<template>
  <div>
    <Header :class="{ bgNone: bgColorShow == true, fixedTop: true }"></Header>
    <div class="video-bg" id="video">
      <video ref="videoPlayer" autoplay="autoplay" loop="loop" class="bgvid">
        <source
          src="https://wys-unilearn.oss-cn-shanghai.aliyuncs.com/home/file.mp4"
          type="video/mp4"
        />
        您的浏览器不支持 video 标签
      </video>
    </div>
    <div class="bg-back"></div>
    <div
      v-show="nextBtn"
      @click="toBody"
      class="arrow-container"
      :style="myStyles"
    >
      <div class="arrow-text">点击进入</div>
      <img src="@/assets/image/toBody.png" />
    </div>
    <!-- pc端隐藏，移动端显示 -->
    <div class="img-bg">
      <div class="text">
        <div class="text-top">
          外语教学与研究出版社研发中心致力构建外语教-学-评一体化服务生态
        </div>
        <div class="text-main">
          <i
            >我们与国内外众多权威专家合作，在英语分级阅读教学、语言测评与教学、外语辞典结构化分析多个领域开展学术研究，开发数字产品；搭建交流平台，共享前沿讯息。以测评服务为牵引，我们整合外研社海量的优质学习和教学资源，持续为广大教师和学生提供立体化解决方案：以评促读，提升思维品质；以评促学，助力个性化学习；以评促教</i
          >
        </div>
        <div class="text-footer">专注专业 共同学习 协力成长</div>
      </div>
    </div>
    <!-- 以上内容pc端隐藏，移动端显示  -->
    <div id="main-body">
      <div class="container">
        <div class="part-title">教学吧</div>
        <el-row :gutter="20" justify="center">
          <el-col :md="8">
            <module :item="teachPart"></module>
          </el-col>
        </el-row>
      </div>
      <div class="bg-gray">
        <div class="container">
          <div class="part-title">测评吧</div>
          <div class="part-sub-title">英语能力测评</div>
          <el-row :gutter="20" justify="center">
            <el-col v-for="(item, index) in enTestPart" :key="index" :md="8">
              <module :item="item" :linkShow="true"></module>
            </el-col>
          </el-row>
          <div class="part-sub-title">非言语智力测评</div>
          <el-row :gutter="20" justify="center">
            <el-col :md="8">
              <module :item="otherTestPart"></module>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="container">
        <div class="part-title">科研吧</div>
        <el-row :gutter="20" justify="center">
          <el-col v-for="(item, index) in researchPart" :key="index" :md="8">
            <module :item="item"></module>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="topPosition">
      <img v-show="!bgColorShow" @click="toTop" src="@/assets/image/top.png" />
    </div>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import module from "@/components/module.vue";
import { moduleInfos } from "@/utils/utils.js";
import { toRefs, reactive, onMounted, onUnmounted, ref } from "vue";
// 引入子组件
export default {
  components: { Header, module },
  setup() {
    const teachPart = moduleInfos["yuejiao"];
    const enTestPart = [
      moduleInfos["pindu"],
      moduleInfos["yueceping"],
      moduleInfos["iway"],
      moduleInfos["primary"],
      moduleInfos["junior"],
      moduleInfos["jizhen"],
      moduleInfos["gaozhen"],
    ];
    const otherTestPart = moduleInfos["siou"];
    const researchPart = [
      moduleInfos["mingti"],
      moduleInfos["tiku"],
      moduleInfos["yuejuan"],
    ];
    const state = reactive({
      bgColorShow: true,
      nextBtn: true,
      myStyles: {
        opacity: "",
        transform: "",
      },
    });
    const videoPlayer = ref(null);
    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
      animateArrow(); //下箭头动画
      videoPlayer.value.muted = true;
    });
    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
    });
    const animateArrow = () => {
      var index = 0;
      setInterval(() => {
        var num = (index % 50) * 1;
        state.myStyles.opacity = 1 - num / 50;
        state.myStyles.transform = "matrix(1, 0, 0, 1, 0, " + num + ")";
        index++;
      }, 50);
    };
    const handleScroll = () => {
      if (!navChange()) {
        state.bgColorShow = false;
      } else {
        state.bgColorShow = true;
      }
      if (document.documentElement.scrollTop > 50) {
        state.nextBtn = false;
      } else {
        state.nextBtn = true;
      }
    };
    const navChange = () => {
      var w = window.innerWidth;
      if (w > 767) {
        return window.innerHeight - document.documentElement.scrollTop > 100;
      }
    };
    const toTop = () => {
      let top = document.documentElement.scrollTop; //获取点击时页面的滚动条纵坐标位置
      const timeTop = setInterval(() => {
        document.documentElement.scrollTop = top -= 40; //一次减40往上滑动
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10); //定时调用函数使其更顺滑
    };
    const toBody = () => {
      let top = document.documentElement.scrollTop; //获取点击时页面的滚动条纵坐标位置
      const timeTop = setInterval(() => {
        document.documentElement.scrollTop = top += 40; //一次减40往上滑动
        if (top >= window.innerHeight - 60) {
          clearInterval(timeTop);
        }
      }, 10); //定时调用函数使其更顺滑
    };
    return {
      ...toRefs(state),
      teachPart,
      enTestPart,
      otherTestPart,
      researchPart,
      handleScroll,
      videoPlayer,
      toTop,
      toBody,
    };
  },
};
</script>
<style scoped>
.part-title {
  font-size: 40px;
  padding: 20px 0;
  color: #0c0c0c;
  text-align: center;
  font-weight: bold;
}
.part-sub-title {
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #5c5c5c;
  font-weight: lighter;
  text-align: center;
}
.bgvid {
  object-fit: fill;
  height: 100vh;
  width: 100vw;
}
.img-bg {
  display: none;
}

.bg-dark {
  transition: background 0.1s linear;
}
.bg-back {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  background-color: #1b1b2b;
  opacity: 0.6;
  height: 100%;
  z-index: 100;
}
.video-bg {
  z-index: -100;
  position: relative;
  overflow: hidden;
}
.bgNone {
  background-color: rgba(255, 255, 255, 0) !important;
}
.topPosition {
  position: fixed;
  right: 1rem;
  bottom: 2rem;
  animation: active 1s;
  -webkit-animation: active 1s;
  overflow: hidden;
}
.topPosition img {
  width: 32px;
  height: 32px;
}
@keyframes active {
  0% {
    height: 0px;
  }

  100% {
    height: 60px;
  }
}
.arrow-container {
  position: fixed;
  bottom: 20px;
  width: 100%;
  text-align: center;
  z-index: 101;
  color: #ffffff;
  cursor: pointer;
}
.arrow-text {
  margin-bottom: -10px;
  font-size: 12px;
}
.arrow-container img {
  width: 50px;
  display: block;
  margin: 0 auto;
}
</style>
