import axios from "axios";
import { refreshToken } from "../utils/utils.js";
import { ElMessage } from "element-plus";
let token = localStorage.getItem("fltrp-home-token");
let tokenType = localStorage.getItem("fltrp-home-tokenType");
const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_PATH,
  timeout: 5000,
  headers: {
    Authorization: tokenType + " " + token,
  },
});
// 拦截器     技术点
http.interceptors.request.use(
  (config) => {
    //console.log("config:", config); // 本次请求的一些信息
    let token =
      localStorage.getItem("fltrp-home-tokenType") +
      " " +
      localStorage.getItem("fltrp-home-token");
    config.headers.Authorization = token;
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);
http.interceptors.response.use(
  (res) => {
    //console.log("res:", res); // 本次服务器响应的一些信息
    return res.data; // 返回的这个res 被then方法的res形参接收了
  },
  (err) => {
    //console.log(err.response.status);
    var hashs = window.location.hash.substring(0, 7);
    //令牌失效
    if (err.response.status == 401) {
      let p = refreshToken();
      p.then((flag) => {
        if (flag) {
          //window.location.reload();
        } else {
          localStorage.clear();
          if (hashs == "#/login" || hashs == "#/login") {
            //如果本来就在登录页则不做任何操作
          } else {
            ElMessage({
              message: "登录已失效，即将跳转登录页...",
              type: "warning",
            });
            //跳转至登录页面
            setTimeout("window.location.href = '/#/login'", 3000); //上线前需修改
          }
        }
      });
    }
    return Promise.reject(err);
  }
);

export { http };
