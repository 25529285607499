import axios from "axios";
const qs = require("qs");

export let lastPostTime = 0;
export let heartBeatSending = false;
export let heartBeatOptions = {};

export default {
  install(app, options) {
    options = options || {};
    heartBeatOptions = {
      url:
        options.url || "https://unilearn.fltrp.com/accountapi/auth/checkToken",
      responseType: options.responseType || "POST",
      dataType: options.dataType || "json",
      data: options.data || {},
      header: options.header || {
        Authorization:
          "bearer " + window.localStorage.getItem("fltrp-home-token"),
      },
      timeout: options.timeout || 1000 * 10,
      duration: options.duration || 1000 * 60,
      callback: options.callback || function () {},
      errorFun: options.errorFun || function () {},
    };

    const sendHeartBeat = function () {
      var currentTime = new Date().getTime();
      if (
        !heartBeatSending &&
        currentTime - lastPostTime > heartBeatOptions.duration
      ) {
        heartBeatSending = true;
        if (heartBeatOptions.responseType.toUpperCase() === "GET") {
          let params = "?";
          for (let key in heartBeatOptions.data) {
            if (
              heartBeatOptions.data[key] === 0 ||
              heartBeatOptions.data[key]
            ) {
              params += key + "=" + heartBeatOptions.data[key] + "&";
            }
          }
          axios({
            url: heartBeatOptions.url + params,
            method: "get",
            timeout: heartBeatOptions.timeout,
            transformRequest: [
              function (data) {
                return qs.stringify(data, { indices: false });
              },
            ],
            headers: heartBeatOptions.header,
          })
            .then(function (response) {
              heartBeatSending = false;
              lastPostTime = currentTime;
              heartBeatOptions.callback(response);
            })
            .catch(function (error) {
              heartBeatSending = false;
              lastPostTime = currentTime;
              heartBeatOptions.errorFun(error);
            });
        } else {
          axios({
            url: heartBeatOptions.url,
            method: heartBeatOptions.responseType,
            timeout: heartBeatOptions.timeout,
            transformRequest: [
              function (data) {
                let param = {};
                for (let key in data) {
                  if (data[key] === 0 || data[key]) {
                    param[key] = data[key];
                  }
                }
                return qs.stringify(param, { indices: false });
              },
            ],
            headers: heartBeatOptions.header,
            data: heartBeatOptions.data,
          })
            .then(function (response) {
              heartBeatSending = false;
              lastPostTime = currentTime;
              heartBeatOptions.callback(response);
            })
            .catch(function (error) {
              heartBeatSending = false;
              lastPostTime = currentTime;
              heartBeatOptions.errorFun(error);
            });
        }
      }
    };

    app.config.globalProperties.$setHeartBeatData = function (data) {
      for (let key in data) {
        heartBeatOptions.data[key] = data[key];
      }
    };

    app.config.globalProperties.$startHeartBeat = function () {
      window.onmousemove = () => {
        sendHeartBeat();
      };
      window.onkeyup = () => {
        sendHeartBeat();
      };
      window.onclick = () => {
        sendHeartBeat();
      };
    };

    app.config.globalProperties.$stopHeartBeat = function () {
      window.onmousemove = () => {};
      window.onkeyup = () => {};
      window.onclick = () => {};
    };
  },
};
