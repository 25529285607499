<template>
  <div v-if="!netOut">
    <el-container id="app">
      <el-main style="padding: 0; margin: 0">
        <router-view v-if="isRouterActive"></router-view>
      </el-main>
      <el-footer class="el-footer">
        <Footer></Footer>
      </el-footer>
    </el-container>
  </div>
  <div v-if="netOut" class="net-out">
    <img class="nonet" src="@/assets/image/networkOut.png" />
    <p>未连接到互联网</p>
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import { reactive, toRefs, ref, provide, nextTick } from "vue-demi";
import { onMounted, getCurrentInstance } from "vue";
export default {
  components: { Footer },
  setup() {
    const { proxy } = getCurrentInstance(); // 使用proxy代替ctx，因为ctx只在开发环境有效
    //let options = { url: process.env.VUE_APP_BASE_PATH + "/auth/checkToken" };
    const state = reactive({
      netOut: !navigator.onLine,
    });
    onMounted(() => {
      // 检测断网
      window.addEventListener("offline", () => {
        state.netOut = true;
      });
      window.addEventListener("online", () => {
        state.netOut = false;
      });
      proxy.$setHeartBeatData();
      proxy.$startHeartBeat();
    });
    const isRouterActive = ref(true);
    provide("reload", () => {
      isRouterActive.value = false;
      nextTick(() => {
        isRouterActive.value = true;
      });
    });
    return {
      ...toRefs(state),
      isRouterActive,
    };
  },
};
</script>

<style>
.el-alert .el-alert__icon {
  color: red;
}
.el-alert {
  --el-alert-icon-size: 22px;
}
.module .el-dialog {
  --el-dialog-width: 41%;
}
.is-message-box .btn-outline-primary {
  border: 2px solid #007bff;
  padding: 15px 25px;
  border-radius: 10px;
  color: #007bff;
  font-size: 1rem;
}
@media screen and (max-width: 768px) {
  .container {
    width: 100% !important;
    padding: 0 !important;
  }
  .login-Card {
    width: 90% !important;
  }
  .box-card {
    width: 96% !important;
  }
  .el-form-item__label {
    width: 70px !important;
  }
  .warnigBig {
    margin-bottom: 28px !important;
  }
  .el-col-offset-6 {
    margin-left: 10% !important;
  }
  .mobileFooter {
    margin-top: 20px;
    margin-left: 10%;
  }
  .video-bg {
    display: none;
  }
  .img-bg {
    background: url("assets/image/splish.jpg") 0px 0px no-repeat;
    background-size: cover;
    height: 100vh;
    display: block !important;
  }
  .text {
    padding-top: 22%;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .text-top {
    margin-bottom: 2rem;
  }
  .text-top,
  .text-footer {
    font: normal 24px/160% arial, microsoft yahei;
    text-align: center;
    color: #eee;
  }
  .text-main {
    color: #636d82;
    line-height: 28px;
    margin-bottom: 2rem;
    font-style: italic;
  }
  .loggedLeft,
  .logged,
  .logout-btn,
  .head-tip {
    display: none !important;
  }
  .el-col-offset-4 {
    margin-left: 0 !important;
  }
  .mobilCenter {
    text-align: center;
  }
  .port-text {
    margin-left: 0 !important;
    margin-top: 20px !important;
  }
  .mobile-btn .el-form-item__content {
    margin-left: 10px !important;
    justify-content: center;
  }
  .el-dropdown-menu__item {
    justify-content: center;
  }
  .el-dropdown-menu__item a {
    color: var(--el-text-color-regular) !important;
  }
  .el-message-box {
    width: 90% !important;
  }
  .module .el-dialog {
    --el-dialog-width: 90% !important;
  }
  .el-dialog {
    --el-dialog-width: 90% !important;
  }
  .custom-control-label {
    width: 275px !important;
  }
  .mobileAgreement .el-form-item__content {
    margin-left: 0px !important;
  }
  .bg-back {
    display: none;
  }
  .bg .headerL img {
    height: 2.3rem !important;
    padding-top: 0.9rem !important;
    padding-right: 0.5rem !important;
  }
  .bg .title {
    font-size: 1rem !important;
    text-align: left !important;
  }
  .confirmTipText {
    font-size: 0.9rem !important;
  }
}

.el-footer {
  padding: 0 !important;
  height: 100%;
  overflow: hidden;
}
.bg-gray {
  background-color: #eeeeee;
}
.container {
  width: 80%;
  margin: 40px auto;
  justify-content: center;
}
.card-header {
  text-align: center;
}
.box-card {
  width: 50%;
  margin: 50px auto;
}
.largeBtn {
  width: 300px;
}
.clearFloat {
  overflow: hidden;
}
.floatR {
  float: right;
}
.w100 {
  width: 100%;
}
.ml20 {
  margin-left: 20px;
}
.mt30 {
  margin-top: 30px;
}
.text-danger {
  color: red;
}
.custom-control-label {
  width: 360px;
  font-size: 12px;
}
.custom-control-input {
  width: 8px;
  margin-right: 15px;
}
.h60 {
  height: 60px;
}

.eye-p {
  position: absolute;
  top: 6px;
  right: 10px;
  cursor: pointer;
}
.eye-p img {
  width: 20px;
}
.code-img {
  cursor: pointer;
}
.net-out {
  padding-top: 10%;
  text-align: center;
}
.net-out img {
  padding-bottom: 1rem;
}
</style>
