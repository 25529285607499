import { http } from "@/api/request.js";
import qs from "qs";
import { localStore } from "@/utils/utils.js";
//登录
export const toLogin = (dataObj) => {
  let data = qs.stringify(dataObj);
  return http.post("/auth/token", data);
};
//退出
export const logout = () => {
  return http.delete("/auth/token");
};
//验证是否登录
export const toCheckToken = () => {
  return http.post("/auth/checkToken");
};
//发送验证码
export const toSendVerify = (dataObj) => {
  let data = qs.stringify(dataObj);
  return http.post("/sms/sendCode", data);
};
//校验验证码是否正确
export const toVerifyCode = (dataObj) => {
  let data = qs.stringify(dataObj);
  return http.post("/sms/verifyCode", data);
};
//注册
export const toRegister = (dataObj) => {
  let data = qs.stringify(dataObj);
  return http.post("/users/register", data);
};
//找回密码
export const toResetPassword = (dataObj) => {
  let data = qs.stringify(dataObj);
  return http.put("/users/retrievePassword", data);
};
//修改密码
export const toModifyPassword = (dataObj) => {
  let data = qs.stringify(dataObj);
  return http.put("/users/expiredPassword", data);
};
//获取用户信息
export const toGetUserInfo = () => {
  return http.get("/users/" + localStore("userId"));
};
//设置用户信息
export const toSetUserInfo = (dataObj) => {
  return http.put("/users/" + localStore("userId"), dataObj);
};
//修改/设置密码
export const toSavePassword = (dataObj) => {
  let data = qs.stringify(dataObj);
  return http.put("/users/" + localStore("userId") + "/password", data);
};
//修改/设置手机号
export const toSaveTel = (dataObj) => {
  let data = qs.stringify(dataObj);
  return http.put("/users/" + localStore("userId") + "/mobile", data);
};
//判断token是否过期
export const checkToken = () => {
  return http.post("/auth/checkToken");
};
//系统管理员二次验证
export const toAgainAuth = (dataObj) => {
  let data = qs.stringify(dataObj);
  return http.post("/auth/againAuth", data);
};
//绑定手机
export const toBindMobile = (dataObj) => {
  let data = qs.stringify(dataObj);
  return http.post("/users/bindMobile", data);
};
//解绑手机号
export const toConfirmBindMobile = () => {
  return http.post("/users/confirmBindMobile");
};
//解绑当前账户的手机号
export const toUnbindCurrentMobile = () => {
  return http.post("/users/unbindMobileForCurrentUser");
};
//注销用户
export const toUnRegister = (dataObj) => {
  let data = qs.stringify(dataObj);
  return http.post("/users/unregister", data);
};
