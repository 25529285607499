import router from "../router/index";
import VueMatomo from "vue-matomo";
import { localStore } from "@/utils/utils.js";
export default function (app) {
  app.use(VueMatomo, {
    host: "https://analytics.fltrp.com/matomo/", //你自己的matomo地址
    siteId: "1", //这个值页需要去matomo上去申请
    trackerFileName: "matomo",
    router: router,
    enableLinkTracking: true,
    requireConsent: false,
    trackInitialView: true,
    enableHeartBeatTimer: 60,
    debug: false,
    userId: localStore("userId"), //当前用户登录Id,可根据需求来设置，非必传，也可以在用户登录成功之后设置
  });
}
