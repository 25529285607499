import { createApp } from "vue";
import App from "./App.vue";

import router from "./router";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import locale from "element-plus/es/locale/lang/zh-cn";
import axios from "axios";

import "./assets/css/reset.css";
import store from "./store";
import HeartBeat from "@/utils/vue-heart-beat.js";

import { JSEncrypt } from "jsencrypt";

import manotoUse from "./utils/manotoUse";
const app = createApp(App);
app.config.globalProperties.$http = axios;
//3.挂载全局方法
app.config.globalProperties.$encryption = function (password) {
  //password是要加密的对象
  let encryptor = new JSEncrypt(); // 新建JSEncrypt对象
  encryptor.setPublicKey(
    "-----BEGIN PUBLIC KEY-----\n" +
      "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDFzpRvF4a5DDStBfdwTcHhCzo5\n" +
      "PjzVvbDrfsH25pmTQjLCK4FVP/2mhSr7/fmWDUnR0Fzc20DFsJ+wFiPGZ0vhqKfg\n" +
      "12wgWESRN6FHliKe+drDc9M6XFgJjXNp6T8Xj36vggpHJNzQMjEWW+y/OvyTW0A9\n" +
      "7ZmnjTpRlleixWuGsQIDAQAB\n" +
      "-----END PUBLIC KEY-----"
  ); // 设置公钥
  let passwordEncryp = encryptor.encrypt(password); // 对密码进行加密
  return passwordEncryp;
};
app.config.globalProperties.$haveToken = false;
app.config.globalProperties.$passwordTip = false;
const globals = app.config.globalProperties;
manotoUse(app);
app
  .use(router)
  .use(store)
  .use(ElementPlus, { locale })
  .use(HeartBeat)
  .mount("#app");

export { globals };
