<template>
  <div class="footer">
    <el-row :gutter="10">
      <el-col :span="8" :offset="6" :xs="22">
        <div class="box-header"><span>友情链接</span></div>
        <div class="box-content">
          <ul>
            <li>
              <a
                href="https://www.fltrp.com/"
                target="_blank"
                title="外语教学与研究出版社"
                >外语教学与研究出版社</a
              >
            </li>
            <li>
              <a
                href="https://chinareading.fltrp.com/"
                target="_blank"
                title="中国英语阅读教育研究院"
                >中国英语阅读教育研究院</a
              >
            </li>
            <li>
              <a
                href="http://far.fltrp.com"
                target="_blank"
                title="中国基础教育外语测评研究基金"
                >中国基础教育外语测评研究基金</a
              >
            </li>
            <li>
              <a
                href="https://udig.fltrp.com/"
                target="_blank"
                title="高校英语诊断测评与教学研究"
                >高校英语诊断测评与教学研究</a
              >
            </li>
            <li>
              <a
                href="https://www.unischool.cn"
                target="_blank"
                title="外研社基础集群官网"
                >外研社基础集群官网</a
              >
            </li>
            <li>
              <a href="http://www.unistudy.top" target="_blank" title="外研阅读"
                >外研阅读</a
              >
            </li>
            <li>
              <a href="https://www.unipus.cn/" target="_blank" title="外研在线"
                >外研在线</a
              >
            </li>
            <li>
              <a
                href="http://www.sinotefl.org.cn/"
                target="_blank"
                title="中国外语与教育研究中心"
                >中国外语与教育研究中心</a
              >
            </li>
          </ul>
        </div>
      </el-col>
      <el-col :span="8" :xs="22" class="mobileFooter">
        <div class="box-header">
          <span>联系我们</span>
        </div>
        <div class="box-content">
          <ul>
            <li class="box-item">
              邮箱：<a
                href="mailto:yanfa@fltrp.com"
                style="text-decoration: underline !important"
                >yanfa@fltrp.com</a
              >
            </li>
            <li class="box-item">电话：010-88819743</li>
            <li class="box-item">
              （悦测评、外研拼读、悦教大讲堂）邮箱：<a
                href="mailto:wangjinmeng@fltrp.com"
                style="text-decoration: underline !important"
                >wangjinmeng@fltrp.com</a
              >
            </li>
            <li class="box-item">
              （悦测评、外研拼读、悦教大讲堂）电话：010-88819188
            </li>
            <li class="box-item">
              地址：北京市西三环北路19号外研社大厦研发中心
            </li>
            <li class="box-item">邮编：100089</li>
          </ul>
        </div>
      </el-col>
    </el-row>
  </div>
  <div class="copyright">
    <div class="row">
      <div class="col-sm text-center">
        外语教学与研究出版社 版权所有 Copyright © 2021-2023 FLTRP, All Rights
        Reserved
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.footer {
  background: #252530;
  color: #555;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
}
.box-header {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
}
.box-content a,
.box-item {
  color: #aaa;
  font-size: 14px;
  line-height: 30px;
}
.box-content a:hover {
  color: #007bff;
}
.copyright {
  background-color: #47475c;
  padding: 1em 0;
  z-index: 1000;
  color: #afafaf;
  font-size: 0.9rem;
  text-align: center;
}
</style>
