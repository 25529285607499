import { createRouter, createWebHashHistory } from "vue-router";
import home from "@/views/Home.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: home,
  },
  {
    path: "/login",
    name: "login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ "@/views/Login.vue");
    },
    meta: { title: "登录" },
  },
  {
    path: "/register",
    name: "register",
    component: function () {
      return import("@/views/Register.vue");
    },
    meta: { title: "注册" },
  },
  {
    path: "/forgetPassword",
    name: "forgetPassword",
    component: function () {
      return import("@/views/ForgetPassword.vue");
    },
    meta: { title: "忘记密码" },
  },
  {
    path: "/modifyPassword",
    name: "modifyPassword",
    component: function () {
      return import("@/views/ModifyPassword.vue");
    },
    meta: { title: "修改密码" },
  },
  {
    path: "/noAuth",
    name: "noAuth",
    component: function () {
      return import("@/views/NoAuth.vue");
    },
    meta: { title: "没有权限提示页" },
  },
  {
    path: "/myApp",
    name: "myApp",
    component: function () {
      return import("@/views/MyApp.vue");
    },
    meta: { title: "我的" },
  },
  {
    path: "/setUserInfo",
    name: "setUserInfo",
    component: function () {
      return import("@/views/SetUserInfo.vue");
    },
    meta: { title: "个人中心" },
  },
  {
    path: "/agreement",
    name: "agreement",
    component: function () {
      return import("@/views/Agreement.vue");
    },
    meta: { title: "用户协议" },
  },
  {
    path: "/historyAgreement",
    name: "historyAgreement",
    component: function () {
      return import("@/views/HistoryAgreement.vue");
    },
    meta: { title: "用户协议历史版本" },
  },
  {
    path: "/privacy",
    name: "privacy",
    component: function () {
      return import("@/views/Privacy.vue");
    },
    meta: { title: "隐私政策" },
  },
  {
    path: "/privacyAbstract",
    name: "privacyAbstract",
    component: function () {
      return import("@/views/PrivacyAbstract.vue");
    },
    meta: { title: "隐私摘要" },
  },
  {
    path: "/childrenPrivacy",
    name: "childrenPrivacy",
    component: function () {
      return import("@/views/ChildrenPrivacy.vue");
    },
    meta: { title: "儿童隐私" },
  },
  {
    path: "/thirdParty",
    name: "thirdParty",
    component: function () {
      return import("@/views/ThirdParty.vue");
    },
    meta: { title: "第三方清单" },
  },
  {
    path: "/personalInfoList",
    name: "personalInfoList",
    component: function () {
      return import("@/views/PersonalInfoList.vue");
    },
    meta: { title: "信息收集清单" },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
