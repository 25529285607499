const validateTruename = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("姓名不能为空"));
  } else {
    if (value.length > 18) {
      callback(new Error("长度不能超过18位"));
    }
    callback();
  }
};
const validateUsername = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("用户名不能为空"));
  } else {
    if (!/^(?![0-9]+$)[0-9a-zA-Z]{6,18}$/.test(value)) {
      callback(new Error("请输入6-18个英文字母或数字，不可为纯数字"));
    }
    callback();
  }
};
//校验密码是否符合规则
const validatePassword = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("密码不能为空"));
  } else {
    var regUpper = /[A-Z]/;
    var regLower = /[a-z]/;
    var regNum = /[0-9]/;
    var regTeShu = new RegExp(
      "[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？+-]"
    );
    var complex = 0;
    if (regLower.test(value)) {
      ++complex;
    }
    if (regUpper.test(value)) {
      ++complex;
    }
    if (regNum.test(value)) {
      ++complex;
    }
    if (regTeShu.test(value)) {
      ++complex;
    }
    if (complex < 2 || value.length < 8 || value.length > 32) {
      callback(
        new Error("请输入8-32位，大小写字母、数字或特殊符号的其中两项！")
      );
    }
    callback();
  }
};
//校验手机号
const validateTel = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("手机号不能为空"));
  } else {
    if (!/^1[0-9]{10}$/.test(value)) {
      callback(new Error("请输入正确的手机号！"));
    }
    callback();
  }
};
//验证码非空验证
const validateNull = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("验证码不能为空"));
  } else {
    callback();
  }
};
//密码非空验证
const validatePassNull = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("密码不能为空"));
  } else {
    callback();
  }
};
export {
  validateTruename,
  validateUsername,
  validatePassword,
  validateTel,
  validateNull,
  validatePassNull,
};
