<template>
  <el-header class="header">
    <div class="headerL">
      <router-link to="/">
        <img src="@/assets/image/logo.png" alt="logo" />
      </router-link>
    </div>
    <div class="loggedLeft" v-show="!loginBtn">
      <router-link class="ml20" to="/">首页</router-link>
      <router-link class="ml20" to="/myApp">我的</router-link>
    </div>
    <div class="headerR" v-show="loginBtn">
      <router-link class="" to="/login">登录 / 注册</router-link>
    </div>
    <div class="logged" v-show="!loginBtn">
      <router-link class="header-info" to="/setUserInfo">
        <el-avatar :size="size" :src="circleUrl" />
        <span>个人中心</span>
      </router-link>
      <div class="logout-btn" @click="toLogout()">退出</div>
    </div>
    <el-dropdown v-show="!loginBtn">
      <span class="el-dropdown-link">
        <img src="@/assets/image/list.png" />
        <el-icon class="el-icon--right">
          <arrow-down />
        </el-icon>
      </span>
      <template #dropdown>
        <el-dropdown-menu>
          <router-link class="" to="/">
            <el-dropdown-item>首页</el-dropdown-item>
          </router-link>
          <router-link class="" to="/myApp">
            <el-dropdown-item divided>我的</el-dropdown-item>
          </router-link>
          <router-link class="" to="/setUserInfo">
            <el-dropdown-item divided>
              <el-avatar :size="size" :src="circleUrl" />
              <span>个人中心</span>
            </el-dropdown-item>
          </router-link>
          <div class="" @click="toLogout()">
            <el-dropdown-item divided>退出</el-dropdown-item>
          </div>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <div v-show="headTip" class="head-tip">（在此处绑定手机）</div>
    <div v-show="pTipShow" class="password-tip">您的密码已超过90天未修改！</div>
  </el-header>
</template>

<script>
import { logout, toGetUserInfo } from "@/api/api.js";
import { localStore } from "@/utils/utils.js";
import { reactive, toRefs, onMounted, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
export default {
  setup() {
    const { proxy } = getCurrentInstance(); // 使用proxy代替ctx，因为ctx只在开发环境有效
    const router = useRouter();
    const state = reactive({
      loginBtn: true,
      circleUrl: require("@/assets/image/people.jpg"),
      headTip: false,
      pTipShow: proxy.$passwordTip,
    });

    onMounted(() => {
      var islogin = localStore("token");
      setHeader();
      setTimeout(() => setTips(), 300);
      if (islogin) {
        getUserInfoInHeader();
      }
    });
    const setTips = () => {
      console.log(proxy.$passwordTip);
      if (localStore("token")) {
        state.pTipShow = proxy.$passwordTip;
      } else {
        state.pTipShow = false;
      }
      console.log(state.pTipShow);
    };
    const setHeader = () => {
      if (localStore("token")) {
        state.loginBtn = false;
        state.circleUrl = localStore("portrait");
      } else {
        state.loginBtn = true;
      }
    };
    //获取用户信息，判断用户是否有绑定的手机号
    const getUserInfoInHeader = () => {
      toGetUserInfo().then((response) => {
        if (!response["data"]["mobile"]) {
          state.headTip = true;
          localStore("hasMobile", false);
          localStore("identity", response["data"]["identity"]);
        } else {
          state.headTip = false;
        }
      });
    };
    //退出
    const toLogout = () => {
      logout().then((response) => {
        if (response["status"] == 0) {
          localStorage.clear();
          setHeader();
          router.push("/login");
        } else {
          ElMessage({
            message: response["msg"],
            type: "warning",
          });
        }
      });
    };
    return {
      ...toRefs(state),
      toLogout,
    };
  },
};
</script>

<style scoped>
.header {
  background-color: #47475c !important;
  overflow: hidden;
  color: rgba(255, 255, 255, 0.5);
}
.header a {
  color: rgba(255, 255, 255, 0.5);
}
.header a:hover,
.logout-btn:hover {
  color: #d0d0d6;
}
.fixedTop {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 101;
}
.headerL {
  float: left;
}
.headerL img {
  height: 3.2rem;
  padding: 0.3rem;
}
.headerR {
  float: right;
  color: #ffffff !important;
  line-height: 60px;
  margin-right: 1rem;
}
.loggedLeft {
  float: left;
  align-items: center;
  height: 60px;
  cursor: pointer;
  line-height: 60px;
}
.logged {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 60px;
  cursor: pointer;
}
.header-info {
  display: flex;
  align-items: center;
}
.header-info span {
  margin-left: 10px;
}
.logout-btn {
  margin-left: 35px;
  margin-right: 20px;
}
.head-tip {
  margin-top: -14px;
  font-size: 12px;
  float: right;
  margin-right: 16px;
}
.el-dropdown-link img {
  width: 30px;
}
.el-dropdown {
  float: right;
  margin-top: 15px;
}
.router-link-active {
  color: #ffffff !important;
}
.password-tip {
  position: absolute;
  color: red;
  right: 1rem;
  top: 70px;
  font-size: 1.2rem;
}
</style>
