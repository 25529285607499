<template>
  <div>
    <div class="module" @click="toSystem(item.key, item.url, item.win)">
      <div class="module-logo">
        <img :src="require('@/assets/image/' + item.logo)" />
      </div>
      <p class="module-note" v-html="item.des"></p>
      <a
        @click="toExperience"
        v-if="item.key == 'gaozhen' && linkShow == true"
        class="linkBtn"
        target="_blank"
      >
        <img src="@/assets/image/detail.png" />
        <span>快速体验</span>
      </a>
      <el-dialog
        v-model="bindFormVisible"
        title="绑定手机号"
        :close-on-click-modal="false"
        :show-close="false"
        :destroy-on-close="true"
      >
        <el-form ref="bFormRef" :model="bindForm" :rules="bRules">
          <el-form-item label="手机号：" prop="tel">
            <el-input :rows="2" v-model="bindForm.tel" />
          </el-form-item>
          <el-form-item label="验证码：" prop="code">
            <el-row :gutter="20">
              <el-col :span="18" :xs="12">
                <el-input :rows="2" v-model="bindForm.code" />
              </el-col>
              <el-col v-show="sendBtnShow" :span="4">
                <el-button
                  @click="
                    sendVerify(bindForm.tel, 'bindPhone');
                    setTime();
                  "
                  >发送验证码</el-button
                >
              </el-col>
              <el-col v-show="!sendBtnShow" :span="6">
                <el-button>重新发送({{ counter }}s)</el-button>
              </el-col>
            </el-row>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="closeBind">取消</el-button>
            <el-button type="primary" @click="bindMobile"> 绑定 </el-button>
          </span>
        </template>
      </el-dialog>
      <el-dialog
        v-model="confirmVisible"
        title="是否继续绑定手机号"
        :close-on-click-modal="false"
        :show-close="false"
      >
        <div class="tipText">
          该手机号已绑定<span>{{ accountName }}</span
          >帐号，
          如需继续绑定到当前账户，请在2分钟内点击<span>“确认”</span>按钮。
          <p>
            确认后<span>{{ accountName }}</span
            >账号只能用该用户名登录，请注意保存。
          </p>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="confirmVisible = false">取消</el-button>
            <el-button type="primary" @click="closeConfirmOpenUn">
              确认
            </el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { localStore, moduleInfos, _checkToken } from "@/utils/utils.js";
import { useRouter } from "vue-router";
import { ElMessageBox, ElMessage } from "element-plus";
import { reactive, toRefs, getCurrentInstance } from "vue";
import { toSendVerify, toConfirmBindMobile, toBindMobile } from "@/api/api.js";
import { validateTel, validateNull } from "@/utils/validate.js";
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    linkShow: String,
  },
  setup() {
    const { proxy } = getCurrentInstance(); // 使用proxy代替ctx，因为ctx只在开发环境有效
    const router = useRouter();
    const state = reactive({
      hasMobile: localStore("hasMobile"),
      identity: localStore("identity"),
      bindFormVisible: false,
      bindForm: {
        tel: "",
        code: "",
      },
      unbindForm: {
        tel: "",
        code: "",
      },
      sendBtnShow: true,
      bFormRef: null,
      counter: 60,
      confirmVisible: false,
      accountName: "",
    });
    const bRules = {
      tel: [{ validator: validateTel, trigger: "blur" }],
      code: [{ validator: validateNull, trigger: "blur" }],
    };
    const confirmBindMobile = () => {
      toConfirmBindMobile().then((response) => {
        if (response["status"] == 0) {
          state.bindForm.tel = "";
          state.counter = 0;
          ElMessage({
            message: response["msg"],
            type: "success",
          });
          localStore("hasMobile", true);
        } else {
          ElMessage({
            message: response["msg"],
            type: "warning",
          });
        }
      });
    };
    const bindMobile = () => {
      state.bFormRef.validate((valide) => {
        if (valide) {
          toBindMobile({
            mobile: state.bindForm.tel,
            code: state.bindForm.code,
          }).then((response) => {
            if (response["status"] == 0) {
              state.bindFormVisible = false;
              localStore("hasMobile", true);
              state.bindForm.tel = "";
              state.counter = 0;
              ElMessage({
                message: response["msg"],
                type: "success",
              });
            } else if (response["status"] == 2001) {
              state.confirmVisible = true;
              state.accountName = response["data"];
            } else {
              ElMessage({
                message: response["msg"],
                type: "warning",
              });
            }
          });
        }
      });
    };
    const closeBind = () => {
      state.bindFormVisible = false;
      state.bindForm.tel = "";
      state.bindForm.code = "";
      state.counter = 0;
    };
    const closeConfirmOpenUn = () => {
      state.bindFormVisible = false;
      state.confirmVisible = false;
      state.counter = 60;
      confirmBindMobile();
    };
    const toExperience = (event) => {
      window.open("https://unilearn.fltrp.com/gz/student/#/trial/examlist");
      event.stopPropagation();
    };
    //发送验证码
    const sendVerify = (tel, type) => {
      if (/^1[0-9]{10}$/.test(tel)) {
        //校验手机号是否正确
        toSendVerify({
          mobile: tel,
          type: type,
        })
          .then((response) => {
            if (response["status"] == 0) {
              ElMessage({
                message: response["msg"],
                type: "success",
              });
            } else {
              ElMessage({
                message: response["msg"],
                type: "warning",
              });
              state.counter = 0;
            }
          })
          .catch(() => {
            state.counter = 0;
          });
      } else {
        ElMessage({
          message: "请输入正确的手机号！",
          type: "warning",
        });
        state.counter = 0;
      }
    };
    const toSystem = (key, link, win) => {
      if (!win) {
        win = key;
      }
      var log_id = localStore("loginId");
      var info = JSON.parse(localStore("userAppRoles"));
      var appCodeArr = [];
      if (log_id && info) {
        _checkToken();
        if (proxy.$haveToken == true) {
          state.hasMobile = localStore("hasMobile");
          state.identity = localStore("identity");
          if (
            key == "gaozhen" &&
            state.hasMobile == "false" &&
            state.identity != "其他"
          ) {
            state.bindFormVisible = true;
            return;
          }
          //已登录的情况
          info.forEach(function (item) {
            appCodeArr.push(item.appCode);
          });
          if (
            localStore("userRole") == 1 ||
            localStore("userRole") == 2 ||
            localStore("userRole") == 3 ||
            localStore("userRole") == 5 ||
            localStore("userRole") == 6
          ) {
            appCodeArr.push("account");
          }
          if (appCodeArr.indexOf(key) !== -1) {
            window.open(link, win + "-win"); //上线前需修改
          } else {
            // 无权限
            var moduleInfo = moduleInfos[key];
            if (!moduleInfo || (!moduleInfo["html"] && !moduleInfo["btns"])) {
              ElMessageBox.alert("您没有访问该模块的权限！");
            } else {
              if (moduleInfo["html"]) {
                ElMessageBox.alert("联系电话：010-88819188");
              }
              if (moduleInfo["btns"]) {
                var html = "";
                var arr = [];
                for (var i = 0; i < moduleInfo["btns"].length; i++) {
                  var btn = moduleInfo["btns"][i];
                  arr.push(
                    '<a class="btn-outline-primary" href="' +
                      btn["link"] +
                      '" target="_blank"style="border: 2px solid #007bff;padding: 15px 20px;border-radius: 10px;color: #007bff;font-size: 1rem;">' +
                      btn["label"] +
                      "</a>"
                  );
                }
                if (arr.length > 0) {
                  html +=
                    "<div>" + arr.join("&nbsp;&nbsp;&nbsp;&nbsp;") + "</div>";
                }
                ElMessageBox.alert(html, {
                  dangerouslyUseHTMLString: true,
                  center: true,
                  customClass: "btn-outline-primary",
                  showConfirmButton: false,
                });
              }
            }
            return;
          }
        }
      } else {
        //未登录的情况跳转首页
        const toLogin = router.resolve({
          path: "/login",
          query: {
            appCode: key,
            appUrl: link,
          },
        });
        window.open(toLogin.href, "_blank"); // 打开新的窗口(跳转路径，跳转类型)
      }
    };
    const setTime = () => {
      var timer;
      if (state.sendBtnShow) {
        state.sendBtnShow = false;
        timer = setInterval(() => {
          if (state.counter > 0 && state.counter <= 60) {
            state.counter--;
          } else {
            state.sendBtnShow = true;
            state.counter = 60;
            clearInterval(timer);
          }
        }, 1000);
      }
    };
    return {
      ...toRefs(state),
      toSystem,
      setTime,
      sendVerify,
      closeConfirmOpenUn,
      closeBind,
      bRules,
      bindMobile,
      toExperience,
    };
  },
};
</script>

<style scoped>
.module {
  height: 260px;
  padding: 20px;
  margin-bottom: 20px;
}

.module:hover {
  box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.15) !important;
  transition: box-shadow 0.3s;
  cursor: pointer;
}

.shadow {
  box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.15) !important;
  transition: box-shadow 0.3s;
}

.module-logo {
  margin-bottom: 20px;
  text-align: center;
}

.module-logo img {
  height: 80px;
}

.module-note {
  margin-bottom: 0;
  padding-bottom: 14px;
  color: #5c5c5c;
  line-height: 1.5;
}
.btn-outline-primary {
  color: #007bff;
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.linkBtn {
  color: #409eff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 95px;
  float: right;
}
.linkBtn img {
  width: 30px;
}

.tipText {
  line-height: 30px;
  text-indent: 2rem;
}
.tipText span {
  font-weight: bold;
}
</style>
