import { toSendVerify, toVerifyCode, checkToken } from "@/api/api.js";
import { ElMessage } from "element-plus";
import { globals } from "@/main";
import axios from "axios";
import qs from "qs";
//本地存储的存或取
const localStore = (key, value) => {
  if (value !== undefined && value !== null) {
    window.localStorage.setItem("fltrp-home-" + key, value);
  } else {
    return window.localStorage.getItem("fltrp-home-" + key);
  }
};
const moduleInfos = {
  account: {
    key: "account",
    name: "管理中心",
    logo: "account.png",
    des: "您可以进入该模块创建、查找学生账号、重置密码、转班等。",
    url: "account/#/turn",
  },
  yueceping: {
    key: "yueceping",
    name: "悦测评",
    logo: "ycp.png",
    des: "悦测评为K-12阶段学生提供自适应英语定级测试，精准定位学生的阅读水平，为学生推荐符合其水平难度的图书，并为中小学生分年龄、分学段阅读提供科学阅读指导。",
    url: "ycp/route.html",
    btns: [
      {
        label: "体验测试",
        link: "https://unilearn.fltrp.com/ycp/testPreview.html",
      },
    ],
  },
  yuejiao: {
    key: "yuejiao",
    name: "悦教大讲堂",
    logo: "yj.png",
    des: "悦教大讲堂旨在聚焦英语阅读，服务教师发展，为英语教师提供理论、实践、科研、示范课四大类阅读教学在线课程及认证证书等支持，知名专家主讲，解决教师阅读教学中的问题。",
    url: "yuejiao/route.html",
  },
  pindu: {
    key: "pindu",
    name: "外研拼读",
    logo: "pd.png",
    des: '外研拼读为中小学生英语拼读测评提供在线工具，旨在通过测评，展示学生拼读能力现状，为学生学习和教师教学提供反馈，内含拼读定级测试及语音意识、自然拼读、sight words练习题。<b><i class="text-danger" style="font-size: 12px;">（定级测试：50元/次）</i></b>',
    url: "pd/turn.html",
    //无权限时提示文本
    html: '<div class="tel">联系电话：<strong>010-88819188</strong></div>',
  },
  iway: {
    key: "iway",
    name: "iWay",
    logo: "way.png",
    des: "iWay英语能力等级测试系统采用自适应算法，自动推送与测试者能力相匹配的题目，迅速锁定测试者水平，准确判定级别，帮助英语学习者精准了解自己的英语能力水平。",
    url: "way/menu.html",
  },
  primary: {
    key: "primary",
    name: "优诊学(小学版)",
    logo: "primary.png",
    des: "优诊学（小学版）通过在线诊断一即时反馈一实施补救一有效提高的诊学模式，可帮助小学生定位英语能力的强弱项，优化学习策略、提高学习成绩，可帮助教师明确教学重难点、调整教学计划、实施精准教学。",
    url: "primary",
  },
  junior: {
    key: "junior",
    name: "优诊学(初中版)",
    logo: "junior.png",
    des: "优诊学（初中版）通过在线诊断一即时反馈一实施补救一有效提高的诊学模式，可帮助初中生定位英语能力的强弱项，优化学习策略、提高学习成绩，可帮助教师明确教学重难点、调整教学计划、实施精准教学。",
    url: "junior",
  },
  jizhen: {
    key: "jizhen",
    name: "优诊学(高中版)",
    logo: "udig.png",
    des: "优诊学（高中版）通过在线诊断——即时反馈——实施补救——有效提高的诊学模式，帮助高中生定期诊断其英语能力的优势与不足，为教师和学生下一步的教与学提供反馈和建议以及有针对性的学习资源。",
    url: "uzx",
  },
  gaozhen: {
    key: "gaozhen",
    name: "优诊学(高校版)",
    logo: "gz.png",
    des: "优诊学（高校版）致力于服务高校英语精准教学及大学生自主学习，为高校师生提供精细的多技能诊断测评和分班测试，并提供即时、丰富的个人和班级反馈报告，以诊促学，以诊促教。",
    url: "gz/student/router.html",
    btns: [
      {
        label: "快速体验",
        link: "https://unilearn.fltrp.com/gz/student/#/trial/examlist",
      },
      {
        label: "申请试用",
        link: "https://www.wjx.top/m/59635733.aspx",
      },
    ],
  },
  siou: {
    key: "siou",
    name: "斯欧",
    logo: "so.png",
    des: "斯-欧非言语智力测验包括四个分测验：类比测验(Analogies)，拼板测验(Mosaics)，归类测验(Categories)，以及连线测验(Patterns)，是一套用来测量儿童及成人智力的非言语智力测验。",
    url: "siou/route.html",
  },
  mingti: {
    key: "mingti",
    name: "协同命题",
    logo: "idp.png",
    des: "协同命题平台是由外研社自主研发，为各类考试研发团队提供项目及命题管理服务的在线操作系统，能满足多种考试的命题管理、数据存储要求。",
    url: "idp",
  },
  tiku: {
    key: "tiku",
    name: "外研题库",
    logo: "ib.png",
    des: "试题依据测量理论命制，内容经过专家把关和试测检验，质量过硬、分类丰富，便于检索，为诊-教-学提供坚实基础。",
    url: "ib/turn.html",
  },
  yuejuan: {
    key: "yuejuan",
    name: "外研阅卷",
    logo: "m.png",
    des: "阅卷系统是由外研社自主研发的线上阅卷、数据存储平台。能满足多种题型阅卷以及多人同时阅卷，操作简便，数据存入和导出准确、快捷。",
    url: "marking/turn.html",
  },
  tongji: {
    key: "tongji",
    name: "统计中心",
    logo: "count.png",
    des: "外研教学评数据统计系统为您提供优诊学(初中版)、优诊学(高中版)、优诊学(高校版)、悦教大讲堂、悦测评的数据统计功能！",
    url: "tongji/route.html",
  },
  book: {
    key: "book",
    name: "读物管理系统",
    logo: "book.png",
    des: "读物管理系统为您提供外研社分级读物管理功能！",
    url: "bms/#/turn",
    //无权限时提示文本
    html: "",
  },
};
//发送验证码
const sendVerify = (tel, type, password) => {
  if (type == "updateTel") {
    var userId = localStore("userId");
  }
  if (/^1[0-9]{10}$/.test(tel)) {
    //校验手机号是否正确
    toSendVerify({
      mobile: tel,
      type: type,
      userId: userId,
      password: password,
    })
      .then((response) => {
        if (response["status"] == 0) {
          ElMessage({
            message: response["msg"],
            type: "success",
          });
        } else {
          ElMessage({
            message: response["msg"],
            type: "warning",
          });
          // eslint-disable-next-line no-undef
          state.counter = 0;
        }
      })
      .catch(() => {
        /* ElMessage({
          message: "发送失败",
          type: "error",
        }); */
        // eslint-disable-next-line no-undef
        state.counter = 0;
      });
  } else {
    ElMessage({
      message: "请输入正确的手机号！",
      type: "warning",
    });
    // eslint-disable-next-line no-undef
    state.counter = 0;
  }
};

//验证验证码是否正确
const verifyCode = (tel, smsCode, type) => {
  if (smsCode) {
    toVerifyCode({
      mobile: tel,
      code: smsCode,
      type: type,
    })
      .then((response) => {
        if (response["status"] == 0) {
          //$("#smsWaring").hide();
        } else {
          ElMessage({
            message: "验证码输入错误",
            type: "error",
          });
        }
      })
      .catch(() => {
        ElMessage({
          message: "发送失败",
          type: "error",
        });
        // eslint-disable-next-line no-undef
        state.counter = 0;
      });
  }
};
// 生成32位UUID
const _createUUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};
//判断token是否过期
const _checkToken = () => {
  checkToken()
    .then((response) => {
      if (response["status"] == 0) {
        globals.$haveToken = true;
        showExpiredPass();
      } else {
        return;
      }
    })
    .catch(() => {
      globals.$haveToken = false;
      return false;
    });
};
//显示90未修改密码
const showExpiredPass = () => {
  if (localStore("isExpiredPass") == "true") {
    if (globals.$haveToken == true) {
      globals.$passwordTip = true;
      return;
    } else {
      globals.$passwordTip = false;
      return;
    }
  } else {
    globals.$passwordTip = false;
  }
};
const refreshToken = async () => {
  // 判断是否存在刷新令牌
  var refreshToken = localStore("refreshToken");
  if (!refreshToken && refreshToken !== "null") {
    return false;
  }
  var flag = false;
  // 创建一个同步请求的axios实例
  const syncAxios = axios.create({
    async: false,
  });
  var url = process.env.VUE_APP_BASE_PATH + "/auth/refreshToken";
  var requestDatas = { refreshToken: refreshToken };
  let requestData = qs.stringify(requestDatas);
  const response = await syncAxios.post(url, requestData);
  if (response["data"]["status"] == 0) {
    flag = true;
    // 保存新的访问令牌到localStorage
    localStore("token", response["data"]["data"]["accessToken"]);
    window.location.reload();
    return flag;
  }
  return flag;
};
export {
  localStore,
  moduleInfos,
  sendVerify,
  verifyCode,
  _createUUID,
  _checkToken,
  refreshToken,
};
